var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(
      _vm.$route.path !== '/' &&
      _vm.$route.path !== '/qinli_app' &&
      _vm.$route.path !== '/enroll'
    )?_c('el-container',{staticClass:"container"},[_c('el-aside',[_c('div',{staticClass:"system"},[_c('div',{staticClass:"organName"},[_vm._v(_vm._s(_vm.mes.org_name))]),_c('div',{staticClass:"systemName"},[_vm._v("图书馆AI科研服务平台")])]),_c('div',{staticClass:"menuBox"},_vm._l((_vm.menuList),function(item,index){return _c('div',{key:item.id,staticClass:"itemBox"},[([7, 11].includes(item.id))?_c('el-divider'):_vm._e(),_c('div',{class:[
              'firstMenu',
              _vm.firstMenuCurrent === item.id && 'firstMenu_active',
            ],on:{"click":function($event){return _vm.firstMenuChange(item, index)}}},[_c('div',{staticClass:"leftBox"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.firstMenuCurrent !== item.id),expression:"firstMenuCurrent !== item.id"}],staticClass:"icon",attrs:{"src":require(`./assets/image/${item.id}.png`),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.firstMenuCurrent === item.id),expression:"firstMenuCurrent === item.id"}],staticClass:"icon",attrs:{"src":require(`./assets/image/${item.id}_active.png`),"alt":""}}),(item.title == '智能咨询' && _vm.num_total)?_c('el-badge',{staticClass:"item",attrs:{"value":_vm.num_total}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(item.title == '智能咨询' && !_vm.num_total)?_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(item.title !== '智能咨询')?_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()],1),(item.subMenuList)?_c('div',{staticClass:"rightBox"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(item.expand),expression:"item.expand"}],staticClass:"el-icon-arrow-down"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!item.expand),expression:"!item.expand"}],staticClass:"el-icon-arrow-right"})]):_vm._e()]),(item.subMenuList && item.expand)?_c('div',{staticClass:"subMenu"},_vm._l((item.subMenuList),function(subItem){return _c('div',{key:subItem.id,class:[
                'subItemBox',
                _vm.subMenuCurrent === subItem.id && 'subMenu_active',
              ],on:{"click":function($event){return _vm.subMenuChange(subItem)}}},[_c('span',{class:[
                  'circle',
                  _vm.subMenuCurrent === subItem.id && 'circle_active',
                ]}),(subItem.title == '资源管理' && _vm.num_total)?_c('el-badge',{staticClass:"item",attrs:{"value":_vm.num_total}},[_vm._v(" "+_vm._s(subItem.title)+" ")]):_vm._e(),(subItem.title == '资源管理' && !_vm.num_total)?_c('span',[_vm._v(" "+_vm._s(subItem.title)+" ")]):_vm._e(),(subItem.title !== '资源管理')?_c('span',[_vm._v(" "+_vm._s(subItem.title)+" ")]):_vm._e()],1)}),0):_vm._e()],1)}),0),_c('div',{staticClass:"logo_area"},[_c('img',{attrs:{"src":require("./assets/image/logo.png")}})])]),_c('el-container',[_c('el-header',{attrs:{"height":"80px"}},[_c('div',{staticClass:"modelName"},[_vm._v(_vm._s(_vm.modelName))]),(_vm.isRouterAlives)?_c('div',{staticClass:"userInfo"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"user"},[_c('el-avatar',{attrs:{"shape":"square","size":30,"src":_vm.mes.avatarUrl}}),_c('span',{staticClass:"mobile"},[_vm._v(_vm._s(_vm.phone.replace(/x/g, "*")))]),(_vm.mes.id_photo_state === 2)?_c('el-tag',{staticStyle:{"color":"#fff"},attrs:{"size":"small","color":"#d14e6c"}},[_vm._v("正式账号")]):_c('el-tag',{staticStyle:{"color":"#fff"},attrs:{"size":"small","color":"#d14e6c"}},[_vm._v("临时用户")])],1),_c('div',{staticStyle:{"line-height":"0"}},[_c('img',{staticClass:"icon",attrs:{"src":require("./assets/image/logout.png")},on:{"click":_vm.loginOut}})])]),(_vm.mes.attestation_state === 1)?_c('div',{staticClass:"hint"},[_vm._v(" 下次GPS认证开始时间："+_vm._s(_vm.mes.proveEnd)+" ")]):_c('div',{staticClass:"hint"},[_vm._v(" GPS认证剩余时间 "+_vm._s(_vm.mes.gps_remainder)+" 天 ")])]):_vm._e()]),_c('el-main',{staticStyle:{"padding":"0 !important","min-width":"1180px","overflow":"auto"}},[(_vm.isRouterAlives)?_c('router-view'):_vm._e()],1)],1)],1):_vm._e(),(
      (_vm.$route.path == '/' ||
        _vm.$route.path == '/qinli_app' ||
        _vm.$route.path == '/enroll') &&
      _vm.isRouterAlives
    )?_c('router-view'):_vm._e(),_c('el-dialog',{attrs:{"title":"注册信息","visible":_vm.dialogFormVisible,"width":'500px',"append-to-body":true},on:{"update:visible":function($event){_vm.dialogFormVisible=$event}}},[_c('p',{staticClass:"tit_message"},[_vm._v(" 根据注册信息，我们获取到您的发文信息如下，我们会根据获取到的信息追踪相应的文献，请您核对发文名称是否正确。 ")]),_c('el-form',{attrs:{"model":_vm.form}},[_c('el-form-item',{attrs:{"label":"中文发文名称","label-width":_vm.formLabelWidth}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"英文发文名称","label-width":_vm.formLabelWidth}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.form.en_name),callback:function ($$v) {_vm.$set(_vm.form, "en_name", $$v)},expression:"form.en_name"}})],1),_c('el-form-item',{attrs:{"label":"所属机构","label-width":_vm.formLabelWidth}},[_c('el-input',{attrs:{"disabled":"","autocomplete":"off"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.enroll}},[_vm._v("开始追踪")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }